import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuComponent, AuthBackgroundComponent } from './components';
import { DataTablesModule } from 'angular-datatables';
import { LazyImgDirective } from './directives/lazy-load.img';

@NgModule({
  declarations: [
    MenuComponent,
    AuthBackgroundComponent,
    LazyImgDirective
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [MenuComponent, AuthBackgroundComponent, DataTablesModule, LazyImgDirective]
})
export class SharedModule { }
