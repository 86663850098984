import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  ls = new SecureLS({ isCompression: false });

  constructor() { }

  setLocalStore(name: string, data): void {
    this.ls.set(name, data);
  }

  getLocalStore(name: string) {
    try {
      return this.ls.get(name);
    }
    catch (e) {
      this.ls.clear();
      window.location.reload();
      // return localStorage.getItem(name)
    }
  }

  removeLocalStore(name: string): void {
    this.ls.remove(name);
  }

  removeAll(): void {
    this.ls.removeAll();
  }

  
  public setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getItem(key: string) {
    return localStorage.getItem(key)
  }
  public removeItem(key: string) {
    localStorage.removeItem(key);
  }
  public clear() {
    localStorage.clear();
  }
}
