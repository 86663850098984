import { Injectable } from '@angular/core';
import { interval, timer } from 'rxjs';
import { last, map, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

declare var UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class UikitService {
  

  notificationIndex = 1;
  constructor(private localStorageService: LocalStorageService,private authService: AuthService) { }

  notifyMessage(text: string, type: string) {
    let backgroundColor = '#00B754';
    let notifcationImg = 'saved-changes.svg';
    if (type === 'error') {
      backgroundColor = '#EE2C2C';
      notifcationImg = 'error-icon.svg';
    } else if (type === 'warning'){
      backgroundColor = '#FFEC3F';
      notifcationImg = 'warning.svg';
    }
    // this.notificationIndex++;
    // let source = interval(1000);
    let timerSet: number = 5;
    // let timer$ = timer((timerSet * 1000));
    // let noticationTimer = source.pipe(takeUntil(timer$));
    UIkit.notification(`<div style="display:flex;height:3rem;justify-content:space-between;align-items: center;background:${backgroundColor};text-align:left;color:#ffffff;padding:0rem 4.5rem 0rem 1rem;">
    <div style="display:flex; align-items: center; justify-content: center;"><img loading="lazy" style="margin-right: 1rem;height: 1.5rem;width: 1.5rem;" src='assets/img/icons/${notifcationImg}'><span style="font-size:1rem">${text}</span></div><div style="font-size:0.8rem;font-weight:400;opacity:0.8;">Closing in <span class="notification-timer">${timerSet}</span> seconds</div></div>`,
    {
    pos: 'bottom-center',
    timeout: timerSet*1000
    });
    const isLoggedin = this.localStorageService.getLocalStore('loggedIn');
    this.authService.menuSource.subscribe(data => {
      if(data && isLoggedin) {
        document.querySelector('.uk-notification-bottom-center')?.setAttribute('style','bottom:3rem');
      } else {
        document.querySelector('.uk-notification-bottom-center')?.setAttribute('style','bottom:0rem');
      }
    });
    // noticationTimer
    // .subscribe(
    //   (time)=>{
    //   const notificationArray = document.querySelectorAll('.notification-timer');
    //   notificationArray.forEach(element => {
    //     const timerValue = parseInt(element.innerHTML);
    //     if (element.innerHTML !== 'undefined' && timerValue > 0){
    //       const showTimer = timerValue - 1;
    //       element.innerHTML = showTimer.toString();
    //     }
    //   });
    // })
  }
}
