import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoLoginGuard } from '../core/guards/no-login.guard';
import { UserGuard } from '../core/guards/user.guard';
import { TokenResolverService } from '../core/services/token-resolver.service';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [NoLoginGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    }, {
        path: 'channels',
        loadChildren: () => import('./channels/channels.module').then(m => m.ChannelsModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    }, {
        path: 'teams',
        loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    },
    {
        path: 'competitions',
        loadChildren: () => import('./competitons/competitons.module').then(m => m.CompetitonsModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    },
    {
        path: 'configurations',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    },
    {
        path: 'live-preview',
        loadChildren: () => import('./live-preview/live-preview.module').then(m => m.LivePreviewModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    },
    {
        path: 'warnings',
        loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule),
        canActivate: [UserGuard],
        resolve: {
            data: TokenResolverService
        }
    },
    { path: '404',component :ErrorPageComponent },
    { path: '**', redirectTo: '404', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PublicRoutingModule { }

