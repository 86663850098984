import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { warningCount } from 'src/app/shared/constants/url.constants';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private loggedInSource = new BehaviorSubject<any>(null);
  public loggedIn = this.loggedInSource.asObservable();
  public menuItemIndex = new BehaviorSubject('');
  private warnCountSource = new BehaviorSubject<any>(null);
  public warnCount = this.warnCountSource.asObservable();
  configurations;
  redbee_hook_url;
  image_base_path;
  default_logo;
  default_thumbnail_bg;
  default_event_bg;
  test_mode_duration;
  eventGroupValue = false;
  eventListView = true;
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }
 
  

  setLoggedIn(value) {
    this.loggedInSource.next(value);
  }

  configurationSettings(value: any) {
    this.configurations = value
    this.redbee_hook_url = this.configurations.redbee_hook_url;
    this.image_base_path = this.configurations.image_base_path;
    this.default_logo = this.configurations.default_logo;
    this.default_thumbnail_bg = this.configurations.default_thumbnail_bg;
    this.default_event_bg = this.configurations.default_event_bg;
    this.test_mode_duration = this.configurations.test_mode_duration;
  }

  adjustResolution(inputWidth:number,imgType:string){
    let imgWidth:number;
    let imgHeight:number;
    let height:string;
    switch(imgType) {
      case 'logo':
        imgWidth = this.localStorageService.getLocalStore('cards_width');
        imgHeight = this.localStorageService.getLocalStore('cards_height'); 
        break;
      case 'channel':
        imgWidth = this.localStorageService.getLocalStore('channel_logo_width');
        imgHeight = this.localStorageService.getLocalStore('channel_logo_height'); 
        break;
      case 'team':
        imgWidth = this.localStorageService.getLocalStore('team_logo_width');
        imgHeight = this.localStorageService.getLocalStore('team_logo_height'); 
        break;
      case 'competition':
        imgWidth = this.localStorageService.getLocalStore('competition_logo_width');
        imgHeight = this.localStorageService.getLocalStore('competition_logo_height'); 
        break;
    }
    height= inputWidth*(imgHeight/imgWidth) + 'rem';
    return {height};
  }

  getWarningCountAPI() {
    const countUrl = environment.apiUrl + environment.version + warningCount
    return new Promise((resolve) => {
      return this.http.get(countUrl).subscribe(
        (resp) => {
          if (resp['status_code'] === 200) {
            const warningCountData = resp['data'].count;
            this.warnCountSource.next(warningCountData);
          }
          resolve(resp)
        },
        (error) => {
          resolve(error)
        }
      )
    })
  }

}
