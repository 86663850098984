import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { UikitService } from './uikit.service';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorHandlerService {

  constructor(private authService: AuthService, private uikitService: UikitService,
              private router:Router) { }

  async handleError(error) {
    const errorCode: number = error?.status_code;
    const errorMsg: string = error?.error?.message;
    switch (errorCode) {
      case 401:
        // const reloginResp:any = await this.authService.relogin();
        // if(reloginResp.status_code !== 200) {
          localStorage.clear();
          this.router.navigateByUrl('/auth/login');
        // }
        break;
      case 404:
        this.uikitService.notifyMessage(errorMsg, 'error');
        break;
      case 422:
        this.uikitService.notifyMessage(errorMsg, 'error');
        break;
      case 500:
        this.uikitService.notifyMessage(errorMsg, 'error');
        break;
    }
  }

}
