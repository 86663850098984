<!-- <p>auth-background works!</p> -->
<section class="loginSectionWrapper">
    <div class="loginBoxWrapper">
        <div class="loginLogoWrapper">
            <div class="loginBtsportLogo">
                <img loading="lazy" src="assets/img/logo/bt_sport.svg">
                <div class="vl"></div>
                <p class="loginProductTitle">
                    Thumbnail Management Console
                </p>
            </div>

        </div>

    </div>
</section>