import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { api, configFetch, configUpdate, thumbnailUrl, uploadHelper } from '../../shared/constants/url.constants';
import { LocalStorageService } from './local-storage.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient, private sharedDataService: SharedDataService,
    private localStorageService: LocalStorageService) { }

  initApp = async () => {
    const configURL = environment.apiUrl + environment.version + configFetch;
    return new Promise((resolve) => {
      const uId = this.localStorageService.getLocalStore('u_id');
      if(!uId) {
        return resolve('');
      } else{
        return this.http.get<any>(configURL)
        .subscribe(
          async (response) => {
            if (response.status_code === 200) {
              const respConfigData = response.data;
              this.sharedDataService.configurationSettings(respConfigData);
              this.localStorageService.setLocalStore('config_data', respConfigData);
              await this.sharedDataService.getWarningCountAPI();
              resolve(respConfigData);
            } else {
              resolve(response.error);
            }
          },
          (error) => {
            resolve(error);
          }
        );
    }
    })
  }

  getConfiguration() {
    const configURL = environment.apiUrl + environment.version + configFetch;
    return this.http.get(configURL)
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              return response.data
            } else {
              // console.log(response.error.error.message)
            }
          }
          , (error: HttpErrorResponse) => {
            // console.log(error.error.message)
          }
        )
      );
  }

  updateFields(data) {
    const timeSlotUrl = environment.apiUrl + environment.version + configUpdate;
    return this.http.post(timeSlotUrl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response
        }
      }, (error: HttpErrorResponse) => {
        // console.log(error);
      })
    );
  }

  updateBgImage(data) {
    const uploadUrl = environment.apiUrl + environment.version + uploadHelper;
    return this.http.post(uploadUrl, data).pipe(
      map((response: any) => {
        return response
      }, (error: HttpErrorResponse) => {
        // console.log(error);
      })
    );
  }

  uploadImage(data, url) {
    return this.http.put(url, data).pipe(
      map((response: any) => {
        return response
      }, (error: HttpErrorResponse) => {
        // console.log(error);
      })
    );
  }

  thumbnailGen(data) {
    const thumbnail = environment.apiUrl + environment.version + thumbnailUrl;
    return this.http.post(thumbnail, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response
        } else {
          return response.error
        }
      }, (error: HttpErrorResponse) => {
        return error;
      })
    );
  }
}
