import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenResolverService {

  constructor(private localStorageService: LocalStorageService,private authService: AuthService,
              private router:Router) {}
  async resolve(route: ActivatedRouteSnapshot) {
    const  aT:string = this.localStorageService.getLocalStore('a_t');
    const  rT:string = this.localStorageService.getLocalStore('r_t');
    if(aT && rT) {
      return { aT, rT}
    } else {
      const reloginResp:any = await this.authService.relogin();
      if(reloginResp.status_code === 200) {
        const  reLoginAT = this.localStorageService.getLocalStore('a_t');
        const  reLoginRT = this.localStorageService.getLocalStore('r_t');
        return { reLoginAT, reLoginRT}
      } else {
        localStorage.clear();
        this.router.navigateByUrl('/auth/login');
        return null;
      }
    }
  }
}
