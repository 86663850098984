<div class="bottomFixedNav">
    <div class="leftNav">
        <img loading="lazy" class="navLogo" src="assets/img/logo/bt_sport.svg" alt="BT Sport" />
        <div class="vl"></div>
        <div *ngFor="let item of leftMenu;let index =index;" style="display: flex;">
            <a style="display: block" (click)="selectedItem=index;redirect(index)">
                <div class="navItem" [ngClass]="{'navItemActive':(selectedItem==index)}">
                    <img loading="lazy" src="{{item.image}}" /> {{item.name}}
                </div>
            </a>
            <div class="vl"></div>
        </div>
    </div>
    <div class="rightNav">
        <div class="vl"></div>
        <div *ngFor="let item of rightMenu;let index =index;" style="display: flex;">
            <a style="display: block" (click)="selectedItem=index+6;redirect(index+6)">
                <div class="navItem" [ngClass]="{'navItemActive':(selectedItem==index+6)}">
                    <img loading="lazy" src="{{item.image}}" [ngStyle]="{'padding-right': (rightMenu.length-1) == index ? '0rem':'0.6rem'}" /> {{item.name}}
                    <span id="notifyValue" *ngIf="item.id==='warning' && unreadNotificationValue" [ngClass]="{'notifySelected':(selectedItem == '7')}">{{unreadNotificationValue}}</span>
                </div>
            </a>
            <div class="vl" [ngStyle]="{'display': (rightMenu.length-1) == index ? 'none':'block'}"></div>
        </div>
    </div>
</div>