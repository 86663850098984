import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../services/local-storage.service';
import { ApiErrorHandlerService } from '../services/api-error-handler.service';
import { UikitService } from '../services/uikit.service';
import { sessionAuthURL } from 'src/app/shared/constants/session-url.contants';
import { reLogin } from 'src/app/shared/constants/url.constants';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private localStorageService: LocalStorageService, private apiErrorHandlerService: ApiErrorHandlerService,
              private uikitService: UikitService,private http:HttpClient) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.localStorageService.getLocalStore('a_t');
    const refreshToken = this.localStorageService.getLocalStore('r_t');
    let params = {};
    let body = {};
    let headers: HttpHeaders;
    const sessionURL = sessionAuthURL.find(url => request.url.match(url));
    if (request.url.includes(environment.apiUrl) && !sessionURL) {
      if (request.body instanceof FormData) {
        headers = new HttpHeaders({
          Authorization: 'Bearer ' + accessToken
        });
      }
      if (request.url.match(/auth\/login/) || request.url.match(/auth\/relogin/)) {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
        });
        body = request.body;

      } else if (!request.url.match(/refresh\/token/) && !request.url.match(/auth\/login/)) {
        if (this.localStorageService.getLocalStore('a_t') && !(request.body instanceof FormData)) {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
            Accept: '*/*',
          });
        }
      } else if (request.url.match(/refresh\/token/)) {
        if (this.localStorageService.getLocalStore('r_t')) {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + refreshToken,
            Accept: '*/*',
          });
        }
      }
      if (request.body instanceof FormData) {
        // we are sending a file here
        headers = new HttpHeaders({
          Authorization: 'Bearer ' + accessToken
        });
      }
    } else {
      if (request.method == 'PUT') {
        headers = new HttpHeaders({
          Accept: '*/*',
        });
        body = request.body;
      } else {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        });
        body = request.body;
      }
    }
    if (request.body) {
      body = request.body;
    }
    const cloneReq = request.clone({
      headers, setParams: params, body
    });
    if (!request.url.match(/auth\/relogin/)) {
      return next.handle(cloneReq)
        .pipe(
          tap(res => {
            if (res['body']?.['status_code'] === 200) {
              const successMessage = res?.['body']?.['message'];
              this.uikitService.notifyMessage(successMessage, 'success');
            }
          },
            error => {
              if (error?.error) {
                this.apiErrorHandlerService.handleError(error.error);
              }
            }),
        );
    } else {
      return next.handle(cloneReq)
    }
  };
  
}


