import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.apiUrl + environment.version;

  constructor(private http: HttpClient) { }

  getData(url: string, data) {
    const apiURL = this.apiUrl + url;
    let params: HttpParams = new HttpParams();
    Object.keys(data).forEach((key) => {
      params = params.append(key, data[key]);
    });
    return this.http.get(apiURL, { params })
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              return response
            } else {
              // console.log(response.error.error.message);
            }
          }
          , (error: HttpErrorResponse) => {
            // console.log(error.error.message)
          }
        )
      );
  }

  fetchData(url: string, data) {
    const apiURL = this.apiUrl + url;
    let params: HttpParams = new HttpParams();
    Object.keys(data).forEach((key) => {
      params = params.append(key, data[key]);
    });
    return new Promise((resolve)=>{
      return this.http.get(apiURL, { params }).subscribe(
        (respData) => resolve(respData),
        (error) => resolve(error)
      );
    })
  }

  postData(url: string, postData) {
    const apiURL = this.apiUrl + url;
    return new Promise((resolve, reject) => {
      return this.http.post(apiURL, postData).subscribe(
        (respData) => resolve(respData), 
        (error) => resolve(error)
      );
    })
  }

}
