export const apiUrl = 'https://btsportapi.tv2zdev.com/'
export const version = 'v1/';
export const api = apiUrl + 'api/' + version;

export const login = 'auth/login';
export const forgotPass = 'auth/forgotpass';
export const resetPass = 'auth/resetpass';
export const reLogin = 'auth/relogin';
export const logout = 'auth/logout';

export const manageTeams = 'teams/list'
export const addTeam = "teams/add";
export const loadATeam = "teams/fetch";
export const editTeam = "teams/edit";

export const configFetch = 'configs/fetch';
export const configUpdate = 'configs/update';
export const uploadHelper = 'helpers/upload/url';
export const thumbnailUrl = 'events/generate/thumbnail';

export const manageChannels = 'channels/list';
export const channelsAdd = 'channels/add';
export const fetchChannelUrl = 'channels/fetch';
export const editChannelUrl = 'channels/edit';
export const targetChannels = 'channels/list/target_channels'

export const manageCompetitions = "competitions/list"
export const addCompetitionUrl = 'competitions/add';
export const getCompetitonUrl = 'competitions/fetch';
export const editComeptitonUrl = 'competitions/edit';

export const ingestHistoryUrl = 'events/ingest/list';
export const ingestEvent = 'events/ingest'
export const manageEvents = "events/list"
export const updateTestMode = "events/update/test_mode"

export const fetchEventUrl = 'events/fetch';
export const editEventUrl = 'events/edit';
export const goLiveUrl = 'events/update/live';
export const stopLiveUrl = 'events/update/stop';
export const livePreviewUrl ='events/livePreview';
export const warningCount = 'events/warnings/count';
export const warningProcess ='events/process/warnings';

export const dashboardMetrics = 'dashboard/metrics';
export const dashboardHistory ='dashboard/history';

export const emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$';
export const hhmmssRegex = '/^(?:([01]?\d|2[0-3]):([0-5]?\d):)?([0-5]?\d)$/';
export const passwordRegex = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$'
