import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'btsports_cms';
  verifyAuth;
  showMenu;

  constructor(private authService: AuthService, private router:Router) {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event) => { this.verifyAuth = event['url'].match('auth')});
  }


  ngOnInit(): void {
    this.authService.menuSource.subscribe(data => this.showMenu = data );
  }

}
