import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { last } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { UikitService } from 'src/app/core/services/uikit.service';
import { AuthService } from '../../../core/services/auth.service';
import { LocalStorageService } from '../../../core/services/local-storage.service'
import { SharedDataService } from '../../../core/services/shared-data.service';
import { logout } from '../../constants/url.constants';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  leftMenu: any;
  rightMenu: any;
  selectedItem: any;
  unreadNotificationValue;
  
  constructor(private localStorageService: LocalStorageService, private sharedDataService: SharedDataService,
              private authService: AuthService, private router: Router, private apiService: ApiService, private uikitService: UikitService) {
    this.selectedItem = 0;
    this.authService.menuSource.next(false);
  }

  ngOnInit(): void {
    this.leftMenu = [{
      id: 'dashboard',
      name: 'Dashboard',
      image: 'assets/img/icons/dashboard.svg',
      routeName: '/dashboard'
    }, {
      id: 'events',
      name: 'Events',
      image: 'assets/img/icons/events.svg',
      routeName: '/events/manage'
    },
    {
      id: 'teams',
      name: 'Teams',
      image: 'assets/img/icons/teams.svg',
      routeName: '/teams/manage'

    }, {
      id: 'competitons',
      name: 'Competitions',
      image: 'assets/img/icons/competitions.svg',
      routeName: '/competitions/manage'

    }, {
      id: 'channels',
      name: 'Channels',
      image: 'assets/img/icons/channels.svg',
      routeName: '/channels/manage'
    }, {
      id: 'configurations',
      name: 'Configuration',
      image: 'assets/img/icons/white-settings.svg',
      routeName: '/configurations'
    },]
    this.rightMenu = [{
      id: 'live-preview',
      name: 'Live Preview',
      image: 'assets/img/icons/preview.svg',
      routeName: '/live-preview'
    }, {
      id: 'warning',
      name: 'Warnings',
      image: 'assets/img/icons/logs.svg',
      routeName: '/warnings'
    }, {
      id: 'logout',
      name: '',
      image: 'assets/img/icons/logout.svg',
      routeName: '/auth/login'
    }];
    const loggedIn =this.localStorageService.getLocalStore('loggedIn')
    if (loggedIn){
      this.sharedDataService.setLoggedIn(loggedIn)
    }
    this.sharedDataService.menuItemIndex.subscribe((value) => {
        if(value){
          this.selectedItem = value;
          this.localStorageService.setItem('menuItem', value);
        }
    })
    this.sharedDataService.warnCount.subscribe((data) => this.unreadNotificationValue = data > 9 ? '9+' : data)
  }

  async redirect(index) {
    if (index == 8) {
      this.localStorageService.removeItem('menuItem');
      this.localStorageService.removeItem('loggedIn');
      this.sharedDataService.setLoggedIn(0);
      const logoutResp:any = await this.apiService.postData(logout, {user_id: this.localStorageService.getLocalStore('u_id')});
      if(logoutResp?.status_code === 200){
        this.router.navigateByUrl('/auth/login')
        this.localStorageService.removeAll();
      } else if(logoutResp?.error?.message) {
        this.uikitService.notifyMessage(logoutResp.error.message, 'error');
      } 
    } else {
      this.sharedDataService.menuItemIndex.next(index);
      this.selectedItem = index;
      if(index < 6){
        this.router.navigateByUrl(this.leftMenu[index].routeName);
      } else if (index >5 && index < 8) {
        this.router.navigateByUrl(this.rightMenu[index-6].routeName);
      }
    }
  }

  ngOnDestroy() {
    this.sharedDataService.menuItemIndex.next('')
  }
}
