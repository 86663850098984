import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

  errorPage = 'Dashboard';
  routerUrl = '/dashboard';
  constructor(private router: Router) { }

  ngOnInit(): void {
    const checkAuthMoule = this.router.url.match('/auth/')
    if(checkAuthMoule){
      this.errorPage = 'Login';
      this.routerUrl = '/auth';
    }
  }

}
