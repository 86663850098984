import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-background',
  templateUrl: './auth-background.component.html',
  styleUrls: ['./auth-background.component.css']
})
export class AuthBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
