import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { reLogin, login } from '../../shared/constants/url.constants';
import { LocalStorageService } from './local-storage.service';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  memorySource = new BehaviorSubject<any>({});
  menuSource = new BehaviorSubject(true);
  memoryStorage = this.memorySource.asObservable();
  constructor(private http: HttpClient, private router: Router, private localStorageService: LocalStorageService,
    private sharedDataService: SharedDataService) {

  }

  login(data) {
    const loginAPI: string = environment.apiUrl + environment.version + login;
    return new Promise((resolve) => {
      return this.http.post<any>(loginAPI, data).subscribe(
        async (response) => {
          if (response.status_code === 200) {
            const userData = response.data?.user;
            const respConfigData = response.data?.configuration;
            this.storeLoginData(userData);
            this.storeConfigData(respConfigData);
            await this.sharedDataService.getWarningCountAPI();
            resolve(response)
          }
        },
        (error: HttpErrorResponse) => {
          resolve(error);
        }
      );
    });
  }

  relogin() {
    const reLoginURL: string = environment.apiUrl + environment.version + reLogin;
    const rT = this.localStorageService.getLocalStore('r_t');
    const uId = this.localStorageService.getLocalStore('u_id');
    const postData = { refresh_token: rT, user_id: uId };
    return new Promise((resolve) => {
      this.http.post<any>(reLoginURL, postData).subscribe(
        async (response) => {
          if (response.status_code === 200) {
            const userData = response.data?.user;
            const respConfigData = response.data?.configuration;
            this.storeLoginData(userData);
            this.storeConfigData(respConfigData);
            await this.sharedDataService.getWarningCountAPI();
          }
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          resolve(error);
        }
      );
    })
  }


  storeLoginData(userData) {
    const respData = userData;
    this.localStorageService.setLocalStore('a_t', respData.access_token);
    this.localStorageService.setLocalStore('r_t', respData.refresh_token);
    if (respData.access_token && respData.refresh_token) {
      this.localStorageService.setLocalStore('loggedIn', '1');
      this.sharedDataService.setLoggedIn(1)
    } else {
      this.localStorageService.setLocalStore('loggedIn', '0');
      this.sharedDataService.setLoggedIn(0)
    }
    this.localStorageService.setLocalStore('u_id', respData.user_info);
    this.sharedDataService.menuItemIndex.next('0');
  }

  storeConfigData(respConfigData) {
    this.localStorageService.setLocalStore('cards_width', respConfigData.rendered_thumbnail_width);
    this.localStorageService.setLocalStore('cards_height', respConfigData.rendered_thumbnail_height);
    this.localStorageService.setLocalStore('team_logo_width', respConfigData.team_logo_width);
    this.localStorageService.setLocalStore('team_logo_height', respConfigData.team_logo_height);
    this.localStorageService.setLocalStore('channel_logo_width', respConfigData.channel_logo_width);
    this.localStorageService.setLocalStore('channel_logo_height', respConfigData.channel_logo_height);
    this.localStorageService.setLocalStore('competition_logo_width', respConfigData.competition_logo_width);
    this.localStorageService.setLocalStore('competition_logo_height', respConfigData.competition_logo_height);
    this.localStorageService.setLocalStore('competition_logo_width', respConfigData.competition_logo_width);
    this.localStorageService.setLocalStore('competition_logo_height', respConfigData.competition_logo_height);
    this.localStorageService.setLocalStore('thumbnail_background_width', respConfigData.thumbnail_background_width);
    this.localStorageService.setLocalStore('thumbnail_background_height', respConfigData.thumbnail_background_height);
    this.localStorageService.setLocalStore('background_width', respConfigData.background_width);
    this.localStorageService.setLocalStore('background_height', respConfigData.background_height);
    this.sharedDataService.configurationSettings(respConfigData);
    this.localStorageService.setLocalStore('config_data', respConfigData);
  }



}
